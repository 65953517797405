import { TokenInfo } from "@/app/services/token-api";
import { embeddedWallet } from "@/embedded-wallet";
import { activeConnection, resolveRpcConnections } from "@/rpc-connection";
import {
  sendAndConfirmTransaction,
  SendAndConfirmTransactionResult,
} from "@dflow-protocol/swap-api-utils";
import {
  CU_LIMIT_UNWRAP_SOL,
  CU_LIMIT_WITHDRAW_NATIVE_SOL,
  CU_LIMIT_WITHDRAW_SPL_TOKEN,
  CU_LIMIT_WRAP_SOL,
  SOL_ADDRESS,
  SOL_DECIMALS,
  calculateComputeUnitPriceMicroLamports,
  normalizeScaled,
  wait,
} from "@/utils";
import { createAction } from "@reduxjs/toolkit";
import {
  NATIVE_MINT,
  TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountIdempotentInstruction,
  createCloseAccountInstruction,
  createSyncNativeInstruction,
  createTransferInstruction,
  getAssociatedTokenAddressSync,
  unpackAccount,
  AccountLayout,
} from "@solana/spl-token";
import {
  ComputeBudgetProgram,
  ParsedAccountData,
  PublicKey,
  RpcResponseAndContext,
  SimulatedTransactionResponse,
  SystemProgram,
  TokenAccountsFilter,
  Transaction,
  TransactionInstruction,
  VersionedTransaction,
} from "@solana/web3.js";
import { type Quote } from "./aggregator-api";
import { UnauthorizedError, api } from "./api";
import { ISolana } from "@dynamic-labs/solana";
import type { TransactionStatus } from "@/app/background-transactions";
import { addTokensToCache, getTokenInfoFromCache } from "@/app/services/util";

// Websocket updates will dispatch these actions for other subscribers
export const globalUpdateWalletInfo = createAction<WalletInfo>(
  "global/update/walletInfo",
);
export const globalUpdateTokenAccountByMint = createAction<TokenAccountInfo>(
  "global/update/tokenAccountByMint",
);
export const updateTransactionStatus = createAction<{
  signature: string;
  status: TransactionStatus;
}>("walletApi/updateTransactionStatus");

export const walletApi = api.injectEndpoints({
  endpoints: (builder) => ({
    walletInfo: builder.query<WalletInfo, void>({
      async queryFn(_, { dispatch }) {
        try {
          // First we wait for the connection to be initialized
          const connection = await activeConnection.getReadConnection();
          if (!embeddedWallet.address) {
            return {
              error: {
                status: 400,
                data: "Wallet not connected",
              },
            };
          }
          const balance = await connection.getBalance(
            new PublicKey(embeddedWallet.address),
          );

          const data = {
            balance: balance
              ? Number(normalizeScaled(balance.toString(), SOL_DECIMALS))
              : null,
            rawBalance: balance ? balance.toString() : null,
            walletAddress: embeddedWallet.address,
          };
          dispatch(globalUpdateWalletInfo(data));
          return {
            data,
          };
        } catch (err) {
          return handleError(err, {
            status: 500,
            data: "Error getting wallet balance",
          });
        }
      },
      providesTags: ["WalletBalance"],
      async onCacheEntryAdded(
        _arg,
        { dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // Once the cache entry is added, we subscribe to account changes with the connection websocket
        //? https://redux-toolkit.js.org/rtk-query/usage/streaming-updates#websocket-chat-api
        await cacheDataLoaded;
        const connection = await activeConnection.getReadConnection();
        let subscriptionId: number | null = null;
        try {
          if (!embeddedWallet.address) return;
          const walletPubkey = new PublicKey(embeddedWallet.address);
          subscriptionId = connection.onAccountChange(
            walletPubkey,
            (accountInfo, _context) => {
              updateCachedData((data) => {
                data.rawBalance = accountInfo.lamports.toString();
                data.balance = Number(
                  normalizeScaled(
                    accountInfo.lamports.toString(),
                    SOL_DECIMALS,
                  ),
                );
                dispatch(globalUpdateWalletInfo(data));
              });
            },
          );
        } catch {
          console.error("Failed to subscribe to account changes");
        }

        await cacheEntryRemoved;
        if (subscriptionId) {
          void connection.removeAccountChangeListener(subscriptionId);
        }
      },
    }),
    tokenAccounts: builder.query<TokenAccountInfo[], void>({
      async queryFn() {
        try {
          const connection = await activeConnection.getReadConnection();
          if (!embeddedWallet.address) {
            return { data: [] };
          }
          const pubkey = new PublicKey(embeddedWallet.address);

          const filter: TokenAccountsFilter = {
            programId: TOKEN_PROGRAM_ID,
          };

          const accounts = await connection.getParsedTokenAccountsByOwner(
            pubkey,
            filter,
          );

          const data = accounts.value
            .filter((x) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              const mint = new PublicKey(x.account.data.parsed.info.mint);
              const associatedTokenAccountAddress =
                getAssociatedTokenAddressSync(mint, pubkey);
              return associatedTokenAccountAddress.equals(x.pubkey);
            })
            .map((account) => {
              // prettier-ignore
              {
                const address = account.pubkey.toBase58();
                const parsedAccountInfo = account.account.data
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
                const mintAddress: string = parsedAccountInfo.parsed.info.mint;
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
                const tokenBalance: number = parsedAccountInfo.parsed.info.tokenAmount.uiAmount;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                const tokenRawBalance: string = parsedAccountInfo.parsed.info.tokenAmount.amount;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                const decimals: number = parsedAccountInfo.parsed.info.tokenAmount.decimals;
                return {
                  address,
                  mintAddress,
                  tokenBalance,
                  tokenRawBalance,
                  decimals,
                };
              }
            });
          return { data };
        } catch (error) {
          return { error: { status: 500, data: (error as Error).message } };
        }
      },
      providesTags: ["TokenAccounts"],
      async onCacheEntryAdded(_arg, { getState, dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        const tokens: TokenInfo[] = data
          .filter(
            (x) =>
              x.decimals !== undefined &&
              // This token info data is incomplete so we
              // only add this info if it's not in the cache
              getTokenInfoFromCache(getState, x.mintAddress) === undefined,
          )
          .map((x) => ({
            address: x.mintAddress,
            // decimals must be defined via filter
            decimals: x.decimals ?? 0,
            verified: false,
          }));
        addTokensToCache(dispatch, tokens);
      },
    }),
    tokenAccountByMint: builder.query<
      TokenAccountInfo | null,
      string | undefined
    >({
      async queryFn(mintAddress, { dispatch }) {
        try {
          const connection = await activeConnection.getReadConnection();
          if (!embeddedWallet.address || !mintAddress) {
            return { data: null };
          }
          const ownerPubkey = new PublicKey(embeddedWallet.address);
          const mintPubkey = new PublicKey(mintAddress);
          const associatedTokenAddress = getAssociatedTokenAddressSync(
            mintPubkey,
            ownerPubkey,
          );
          const response = await connection.getParsedAccountInfo(
            associatedTokenAddress,
          );
          const parsedAccountInfo = response.value?.data as
            | ParsedAccountData
            | undefined;
          if (!parsedAccountInfo) {
            dispatch(
              globalUpdateTokenAccountByMint({
                mintAddress,
                tokenBalance: 0,
                tokenRawBalance: "0",
              }),
            );
            return { data: null };
          }

          let data: TokenAccountInfo;
          // prettier-ignore
          {
            const address: string = associatedTokenAddress.toBase58();
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
            const mintAddress: string = parsedAccountInfo.parsed.info.mint;
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
            const tokenBalance: number = parsedAccountInfo.parsed.info.tokenAmount.uiAmount;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
            const tokenRawBalance: string = parsedAccountInfo.parsed.info.tokenAmount.amount;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            const decimals: number = parsedAccountInfo.parsed.info.tokenAmount.decimals;
            data = {
              address,
              mintAddress,
              tokenBalance,
              tokenRawBalance,
              decimals,
            };
          }
          dispatch(globalUpdateTokenAccountByMint(data));
          return { data };
        } catch {
          return {
            error: {
              status: 500,
              data: "Error getting token account",
            },
          };
        }
      },
      providesTags: (result, _error, _mintAddress) => [
        { type: "TokenAccountByMint", id: result?.address ?? "unknown" },
      ],
      async onCacheEntryAdded(
        arg,
        { dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        await cacheDataLoaded;
        const connection = await activeConnection.getReadConnection();
        const tokenAccountAddress = (await cacheDataLoaded).data?.address;
        if (!tokenAccountAddress) return;

        let subscriptionId: number | null = null;
        try {
          if (!embeddedWallet.address) return;
          const pubkey = new PublicKey(tokenAccountAddress);
          subscriptionId = connection.onAccountChange(
            pubkey,
            (accountInfo, _context) => {
              try {
                const parsedAccountInfo = unpackAccount(pubkey, accountInfo);
                updateCachedData((data) => {
                  const decimals = data?.decimals;
                  if (!decimals) return;
                  data.tokenBalance = Number(
                    normalizeScaled(parsedAccountInfo.amount, decimals),
                  );
                  data.tokenRawBalance = parsedAccountInfo.amount.toString();
                  dispatch(globalUpdateTokenAccountByMint(data));
                });
              } catch {
                if (!arg) return;
                dispatch(
                  globalUpdateTokenAccountByMint({
                    tokenBalance: 0,
                    tokenRawBalance: "0",
                    mintAddress: arg,
                  }),
                );
                updateCachedData((data) => {
                  if (!data) return;
                  data.tokenBalance = 0;
                  data.tokenRawBalance = "0";
                });
              }
            },
          );
        } catch {
          console.error("Failed to subscribe to account changes");
        }

        await cacheEntryRemoved;
        if (subscriptionId) {
          void connection.removeAccountChangeListener(subscriptionId);
        }
      },
    }),
    destinationSolBalance: builder.query<{ rawBalance: string }, string>({
      async queryFn(destinationAddress) {
        try {
          const connection = await activeConnection.getReadConnection();
          const balance = await connection.getBalance(
            new PublicKey(destinationAddress),
          );
          return {
            data: { rawBalance: balance.toString() },
          };
        } catch (error) {
          return {
            error: {
              status: 500,
              data: (error as Error).message,
            },
          };
        }
      },
      providesTags: (_r, _e, destinationAddress) => [
        {
          type: "DestinationSolBalance",
          id: destinationAddress,
        },
      ],
    }),
    destinationTokenBalance: builder.query<
      { rawBalance: string; address: string } | null,
      { destinationAddress: string; mintAddress: string }
    >({
      async queryFn({ destinationAddress, mintAddress }) {
        try {
          const ownerPubkey = new PublicKey(destinationAddress);
          const mintPubkey = new PublicKey(mintAddress);
          const associatedTokenAddress = getAssociatedTokenAddressSync(
            mintPubkey,
            ownerPubkey,
          );
          const connection = await activeConnection.getReadConnection();
          const response = await connection.getParsedAccountInfo(
            associatedTokenAddress,
          );
          const parsedAccountInfo = response.value?.data as
            | ParsedAccountData
            | undefined;
          if (!parsedAccountInfo) {
            return { data: null };
          }
          // prettier-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
          const tokenRawBalance: string = parsedAccountInfo.parsed.info.tokenAmount.amount;

          return {
            data: {
              rawBalance: tokenRawBalance,
              address: associatedTokenAddress.toBase58(),
            },
          };
        } catch (error) {
          return {
            error: {
              status: 500,
              data: (error as Error).message,
            },
          };
        }
      },
      providesTags: (result) => [
        {
          type: "DestinationTokenBalance",
          id: result?.address,
        },
      ],
    }),
    tokenAccountsByOwner: builder.query<
      { address: string; mintAddress: string; balance: string }[],
      string
    >({
      async queryFn(ownerAddress) {
        try {
          const connection = await activeConnection.getReadConnection();
          const response = await connection.getTokenAccountsByOwner(
            new PublicKey(ownerAddress),
            {
              programId: TOKEN_PROGRAM_ID,
            },
          );
          const data = response.value.map((info) => {
            const accountInfo = AccountLayout.decode(info.account.data);
            return {
              address: info.pubkey.toBase58(),
              mintAddress: accountInfo.mint.toBase58(),
              balance: accountInfo.amount.toString(),
            };
          });

          return { data };
        } catch (error) {
          return {
            error: {
              status: 500,
              data: (error as Error).message,
            },
          };
        }
      },
    }),
    changeWallet: builder.mutation<null, void>({
      queryFn: async () => {
        // This just needs to invalidate the wallet queries once the wallet changes
        await wait(100);
        return { data: null };
      },
      invalidatesTags: ["WalletBalance", "TokenAccounts", "TokenAccountByMint"],
    }),
    signTransaction: builder.mutation<
      VersionedTransaction,
      VersionedTransaction
    >({
      queryFn: async (
        transaction,
      ): Promise<
        MutationResult<
          VersionedTransaction,
          BuildAndSendTransferTransactionError
        >
      > => {
        try {
          await signTransaction(transaction);
          return {
            data: transaction,
          };
        } catch (error) {
          const e = error as Error | undefined;

          if (e?.message === "cancel_signature") {
            return {
              error: { status: 500, kind: "cancel_signature", data: error },
            };
          }

          return {
            error: { status: 500, kind: "failed_to_sign", data: error },
          };
        }
      },
    }),
    sendTransaction: builder.mutation<
      SendAndConfirmTransactionResult,
      SendTransactionArgs
    >({
      queryFn: async (
        { signature, transaction },
        { dispatch },
      ): Promise<
        MutationResult<SendAndConfirmTransactionResult, SendTransactionError>
      > => {
        try {
          const { read, write } = await resolveRpcConnections();
          const onSendComplete = () => {
            setTimeout(() => {
              dispatch(
                updateTransactionStatus({ signature, status: "confirming" }),
              );
            }, 1000);
          };

          const result = await sendAndConfirmTransaction({
            readConnection: read,
            writeConnections: write,
            transaction,
            onSendComplete,
          });
          return { data: result };
        } catch (error) {
          return {
            error: {
              status: 500,
              kind: "unhandled_error",
              data: error,
            },
          };
        }
      },
      invalidatesTags: ["TokenAccounts"],
    }),
    buildAndSendTransferTransaction: builder.mutation<
      SendAndConfirmTransactionResult,
      BuildTransferTransactionParams
    >({
      queryFn: async (
        params,
      ): Promise<
        MutationResult<
          SendAndConfirmTransactionResult,
          BuildAndSendTransferTransactionError
        >
      > => {
        try {
          const { read, write } = await resolveRpcConnections();
          const transaction = buildTransferTransaction(params);

          try {
            const blockhash = await read.getLatestBlockhash();
            transaction.recentBlockhash = blockhash.blockhash;
          } catch (error) {
            return {
              error: {
                status: 500,
                kind: "failed_to_fetch_latest_blockhash",
                data: error,
              },
            };
          }

          try {
            await signTransaction(transaction);
          } catch (error) {
            const e = error as Error | undefined;

            if (e?.message === "cancel_signature") {
              return {
                error: { status: 500, kind: "cancel_signature", data: error },
              };
            }

            return {
              error: { status: 500, kind: "failed_to_sign", data: error },
            };
          }

          const result = await sendAndConfirmTransaction({
            readConnection: read,
            writeConnections: write,
            transaction,
          });
          return { data: result };
        } catch (error) {
          return {
            error: {
              status: 500,
              kind: "unhandled_error",
              data: error,
            },
          };
        }
      },
      invalidatesTags: [
        "TokenAccounts",
        "DestinationSolBalance",
        "DestinationTokenBalance",
      ],
    }),
    wrapSol: builder.mutation<SendAndConfirmTransactionResult, WrapSolParams>({
      async queryFn({
        walletAddress,
        amountLamports,
        prioritizationFeeLamports,
      }): Promise<
        MutationResult<SendAndConfirmTransactionResult, WrapSolError>
      > {
        try {
          const { read, write } = await resolveRpcConnections();

          const userPublicKey = new PublicKey(walletAddress);
          const wsolTokenAccountAddress = getAssociatedTokenAddressSync(
            NATIVE_MINT,
            userPublicKey,
          );

          const transaction = new Transaction().add(
            ComputeBudgetProgram.setComputeUnitLimit({
              units: CU_LIMIT_WRAP_SOL,
            }),
            ComputeBudgetProgram.setComputeUnitPrice({
              microLamports: calculateComputeUnitPriceMicroLamports(
                prioritizationFeeLamports,
                CU_LIMIT_WRAP_SOL,
              ),
            }),
            createAssociatedTokenAccountIdempotentInstruction(
              userPublicKey,
              wsolTokenAccountAddress,
              userPublicKey,
              NATIVE_MINT,
            ),
            SystemProgram.transfer({
              fromPubkey: userPublicKey,
              toPubkey: wsolTokenAccountAddress,
              lamports: BigInt(amountLamports),
            }),
            createSyncNativeInstruction(wsolTokenAccountAddress),
          );
          transaction.feePayer = userPublicKey;

          try {
            const blockhash = await read.getLatestBlockhash();
            transaction.recentBlockhash = blockhash.blockhash;
          } catch (error) {
            return {
              error: {
                status: 500,
                kind: "failed_to_fetch_latest_blockhash",
                data: error,
              },
            };
          }

          try {
            await signTransaction(transaction);
          } catch (error) {
            const e = error as Error | undefined;

            if (e?.message === "cancel_signature") {
              return {
                error: { status: 500, kind: "cancel_signature", data: error },
              };
            }

            return {
              error: { status: 500, kind: "failed_to_sign", data: error },
            };
          }

          try {
            const simulationResult =
              await read.simulateTransaction(transaction);
            if (simulationResult.value.err) {
              return {
                error: {
                  status: 409,
                  kind: "simulation_failed",
                  data: simulationResult,
                },
              };
            }
          } catch (error) {
            return {
              error: {
                status: 500,
                kind: "not_simulated",
                data: error,
              },
            };
          }

          const result = await sendAndConfirmTransaction({
            readConnection: read,
            writeConnections: write,
            transaction,
          });

          return { data: result };
        } catch (error) {
          return {
            error: {
              status: 500,
              kind: "unhandled_error",
              data: error,
            },
          };
        }
      },
      invalidatesTags: ["TokenAccounts"],
    }),
    unwrapSol: builder.mutation<
      SendAndConfirmTransactionResult,
      UnwrapSolParams
    >({
      async queryFn({
        walletAddress,
        prioritizationFeeLamports,
      }): Promise<
        MutationResult<SendAndConfirmTransactionResult, UnwrapSolError>
      > {
        try {
          const { read, write } = await resolveRpcConnections();

          const userPublicKey = new PublicKey(walletAddress);
          const wsolTokenAccountAddress = getAssociatedTokenAddressSync(
            NATIVE_MINT,
            userPublicKey,
          );

          const transaction = new Transaction().add(
            ComputeBudgetProgram.setComputeUnitLimit({
              units: CU_LIMIT_UNWRAP_SOL,
            }),
            ComputeBudgetProgram.setComputeUnitPrice({
              microLamports: calculateComputeUnitPriceMicroLamports(
                prioritizationFeeLamports,
                CU_LIMIT_UNWRAP_SOL,
              ),
            }),
            createCloseAccountInstruction(
              wsolTokenAccountAddress,
              userPublicKey,
              userPublicKey,
            ),
          );
          transaction.feePayer = userPublicKey;

          try {
            const blockhash = await read.getLatestBlockhash();
            transaction.recentBlockhash = blockhash.blockhash;
          } catch (error) {
            return {
              error: {
                status: 500,
                kind: "failed_to_fetch_latest_blockhash",
                data: error,
              },
            };
          }

          try {
            await signTransaction(transaction);
          } catch (error) {
            const e = error as Error | undefined;

            if (e?.message === "cancel_signature") {
              return {
                error: { status: 500, kind: "cancel_signature", data: error },
              };
            }

            return {
              error: { status: 500, kind: "failed_to_sign", data: error },
            };
          }

          try {
            const simulationResult =
              await read.simulateTransaction(transaction);
            if (simulationResult.value.err) {
              return {
                error: {
                  status: 409,
                  kind: "simulation_failed",
                  data: simulationResult,
                },
              };
            }
          } catch (error) {
            return {
              error: {
                status: 500,
                kind: "not_simulated",
                data: error,
              },
            };
          }

          const result = await sendAndConfirmTransaction({
            readConnection: read,
            writeConnections: write,
            transaction,
          });
          return { data: result };
        } catch (error) {
          return {
            error: {
              status: 500,
              kind: "unhandled_error",
              data: error,
            },
          };
        }
      },
      invalidatesTags: ["TokenAccounts"],
    }),
    latestBlockHeight: builder.query<number, void>({
      async queryFn() {
        try {
          const connection = await activeConnection.getReadConnection();
          const height = await connection.getBlockHeight();
          return { data: height };
        } catch (error) {
          return {
            error: {
              status: 500,
              kind: "failed_to_fetch_latest_blockhash",
              data: (error as Error).message,
            },
          };
        }
      },
    }),
  }),
});

interface WalletApiError {
  type?: "unknown" | "timeout" | "transactionFailed" | "unauthorized";
  status: number;
  data: string;
}

function handleError(
  error: unknown,
  forwardedError: WalletApiError,
): { error: WalletApiError } {
  console.error(error);
  if (error instanceof UnauthorizedError) {
    return {
      error: {
        status: 401,
        data: "Wallet not connected",
      },
    };
  }

  if (
    (error as Error | undefined)?.message.includes(
      "The operation either timed out or was not allowed",
    )
  ) {
    return {
      error: {
        status: 422,
        data: "The operation either timed out or was not allowed",
      },
    };
  }
  return { error: forwardedError };
}

export const {
  useWalletInfoQuery,
  useLazyWalletInfoQuery,
  useTokenAccountsQuery,
  useTokenAccountByMintQuery,
  useChangeWalletMutation,
  useSignTransactionMutation,
  useSendTransactionMutation,
  useBuildAndSendTransferTransactionMutation,
  useWrapSolMutation,
  useUnwrapSolMutation,
  useDestinationSolBalanceQuery,
  useDestinationTokenBalanceQuery,
  useLatestBlockHeightQuery,
  useTokenAccountsByOwnerQuery,
} = walletApi;

export async function signTransaction(
  transaction: Transaction | VersionedTransaction,
): Promise<void> {
  const connector = await embeddedWallet.getConnector();
  if (!connector) {
    throw new UnauthorizedError("Connector not found");
  }

  try {
    const signer = await connector.getSigner<ISolana>();
    await signer.signTransaction(transaction);
  } catch (error) {
    const e = error as Error | undefined;
    if (
      // chrome
      e?.message.includes(
        "The operation either timed out or was not allowed",
      ) ||
      // firefix
      e?.message.includes("The operation was aborted.") ||
      // safari
      e?.message.includes("This request has been cancelled by the user")
    ) {
      throw new Error("cancel_signature");
    }

    throw error;
  }
}

export function buildTransferTransaction({
  from,
  to,
  transfer,
  priorityFee,
}: BuildTransferTransactionParams): Transaction {
  const fromKey = new PublicKey(from);
  const toKey = new PublicKey(to);
  const instructions: TransactionInstruction[] = [];
  const isFullWithdrawal = transfer.amount === transfer.balance;

  if (transfer.tokenAddress === SOL_ADDRESS && transfer.symbol === "SOL") {
    const cuLimit = CU_LIMIT_WITHDRAW_NATIVE_SOL;
    const cuPriceMicroLamports = calculateComputeUnitPriceMicroLamports(
      priorityFee.toString(),
      cuLimit,
    );

    instructions.push(
      ...[
        ComputeBudgetProgram.setComputeUnitLimit({ units: cuLimit }),
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: cuPriceMicroLamports,
        }),
        SystemProgram.transfer({
          fromPubkey: fromKey,
          toPubkey: toKey,
          lamports: transfer.amount,
        }),
      ],
    );
  } else {
    const tokenMint = new PublicKey(transfer.tokenAddress);
    const source = getAssociatedTokenAddressSync(tokenMint, fromKey);
    const dest = getAssociatedTokenAddressSync(tokenMint, toKey);

    const cuLimit = CU_LIMIT_WITHDRAW_SPL_TOKEN;
    const cuPriceMicroLamports = calculateComputeUnitPriceMicroLamports(
      priorityFee.toString(),
      cuLimit,
    );

    instructions.push(
      ...[
        ComputeBudgetProgram.setComputeUnitLimit({ units: cuLimit }),
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: cuPriceMicroLamports,
        }),
        createAssociatedTokenAccountIdempotentInstruction(
          fromKey,
          dest,
          toKey,
          tokenMint,
        ),
        createTransferInstruction(source, dest, fromKey, transfer.amount),
      ],
    );
    if (isFullWithdrawal) {
      instructions.push(
        createCloseAccountInstruction(source, fromKey, fromKey),
      );
    }
  }

  const transferTransaction = new Transaction().add(...instructions);
  transferTransaction.feePayer = fromKey;

  return transferTransaction;
}

export interface SendTransactionArgs {
  signature: string;
  transaction: VersionedTransaction | Transaction;
  lastValidBlockHeight: number;
  swapPair?: { base: TokenInfo; quote: TokenInfo };
  quote?: Quote;
  priorityFeeUsed: number;
}

interface BaseError {
  status: number;
  data: unknown;
}

type MutationResult<
  TOk,
  TErr extends { status: number; kind: string; data: unknown },
> =
  | { data: TOk }
  | {
      error: TErr;
    };

/** Transaction building error types */
type BuildTransactionError = FailedToFetchLatestBlockhash;

/** Web3Auth instance not ready */
interface Web3authNotInitializedError extends BaseError {
  kind: "web3auth_not_initialized";
}

/** Failed to fetch blockhash for the transaction */
interface FailedToFetchLatestBlockhash extends BaseError {
  kind: "failed_to_fetch_latest_blockhash";
}

type SignError = FailedToSignError | CancelSignatureError;

interface CancelSignatureError extends BaseError {
  kind: "cancel_signature";
}

/** An error occurred when attempting to sign the transaction */
interface FailedToSignError extends BaseError {
  kind: "failed_to_sign";
}

/** Transaction simulation error types */
type SimulateError = SimulateErrorNotSimulated | SimulateErrorSimulationFailed;

/** Transaction was not simulated. The simulation request failed. */
interface SimulateErrorNotSimulated extends BaseError {
  kind: "not_simulated";
}

/** Transaction was simulated and failed in simulation  */
interface SimulateErrorSimulationFailed extends BaseError {
  kind: "simulation_failed";
  data: RpcResponseAndContext<SimulatedTransactionResponse>;
}

interface UnhandledError extends BaseError {
  kind: "unhandled_error";
}

type Web3authBuildAndSendTransferTransactionError =
  | Web3authNotInitializedError
  | BuildTransactionError
  | SignError
  | SimulateError
  | UnhandledError;

export function isWeb3authBuildAndSendTransactionError(
  error: unknown,
): error is Web3authBuildAndSendTransferTransactionError {
  return typeof error === "object" && error !== null && "kind" in error;
}

type SendTransactionError = UnhandledError;

export function isSendTransactionError(
  error: unknown,
): error is SendTransactionError {
  return typeof error === "object" && error !== null && "kind" in error;
}

type BuildAndSendTransferTransactionError =
  | BuildTransactionError
  | SignError
  | SimulateError
  | UnhandledError;

export function isBuildAndSendTransactionError(
  error: unknown,
): error is BuildAndSendTransferTransactionError {
  return typeof error === "object" && error !== null && "kind" in error;
}

type SignTransactionError = SignError | UnhandledError;

export function isSignTransactionError(
  error: unknown,
): error is SignTransactionError {
  return typeof error === "object" && error !== null && "kind" in error;
}

type WrapSolError =
  | BuildTransactionError
  | SignError
  | SimulateError
  | UnhandledError;

export function isWrapSolError(error: unknown): error is WrapSolError {
  return typeof error === "object" && error !== null && "kind" in error;
}

type UnwrapSolError =
  | BuildTransactionError
  | SignError
  | SimulateError
  | UnhandledError;

export function isUnwrapSolError(error: unknown): error is UnwrapSolError {
  return typeof error === "object" && error !== null && "kind" in error;
}

export interface SendTransactionCallbacks {
  onSendComplete?: () => void;
}

export interface WalletInfo {
  balance: number | null;
  rawBalance: string | null;
  walletAddress: string | null;
}

export interface BuildTransferTransactionParams {
  from: string;
  to: string;
  priorityFee: number;
  transfer: {
    amount: bigint;
    decimals: number;
    tokenAddress: string;
    balance: bigint;
    symbol?: TokenInfo["symbol"];
  };
}

export interface TokenAccountInfo {
  address?: string;
  mintAddress: string;
  tokenBalance: number;
  tokenRawBalance: string;
  decimals?: number;
}

export interface SubmitQuoteParams {
  userPublicKey: string;
  dynamicComputeUnitLimit: boolean;
  quoteResponse: Quote;
  wrapAndUnwrapSol?: boolean;
}

export interface WrapSolParams {
  amountLamports: string;
  walletAddress: string;
  prioritizationFeeLamports: string;
}

export interface UnwrapSolParams {
  walletAddress: string;
  prioritizationFeeLamports: string;
}
