import zod from "zod";
import { Web3AuthClient } from "./web3auth";
import { TokenInfo, api } from "@/api";
import { resolveRpcConnections } from "@/rpc-connection";
import { PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import {
  TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountIdempotentInstruction,
  createCloseAccountInstruction,
  createTransferInstruction,
  getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import { ComputeBudgetProgram } from "@solana/web3.js";
import { TransactionInstruction } from "@solana/web3.js";
import {
  CU_LIMIT_WITHDRAW_NATIVE_SOL,
  CU_LIMIT_WITHDRAW_SPL_TOKEN,
  MAX_TRANSFER_BATCH_SIZE,
  SOL_ADDRESS,
  SOL_TRANSACTION_FEE,
  TOKEN_ACCOUNT_RENT,
  calculateComputeUnitPriceMicroLamports,
} from "@/utils";
import { Connection } from "@solana/web3.js";
import {
  sendAndConfirmTransaction,
  SendAndConfirmTransactionResult,
} from "@dflow-protocol/swap-api-utils";

const web3authLoginResponseSchema = zod.object({
  refresh_token: zod.string(),
  id_token: zod.string(),
});

export type Web3authLoginResponse = zod.infer<
  typeof web3authLoginResponseSchema
>;

interface OAuthResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: "Bearer";
}

interface GrantError extends Error {
  error: string;
  error_description: string;
}

function getAuth0Error(errorResponse: GrantError, status: number) {
  let type = "";
  let desc = "";

  try {
    type = errorResponse.error;
    desc = errorResponse.error_description;
  } catch {
    return { error: { status, data: "unknown" } };
  }
  switch (type) {
    case "invalid_grant":
      if (desc.includes("Wrong")) {
        return {
          error: {
            status,
            data: { code: "invalid_code", message: "Invalid Code. Try Again." },
          },
        };
      }
      if (desc.includes("maximum")) {
        return {
          error: {
            status,
            data: {
              code: "maximum_attempts",
              message: "No attempts left. Please request a new code.",
            },
          },
        };
      }
      if (desc.includes("expired")) {
        return {
          error: {
            status,
            data: {
              code: "expired_code",
              message:
                "The verification code has expired. Please try to login again.",
            },
          },
        };
      }
      return {
        error: {
          status,
          data: {
            code: "unknown",
            message: "Unknown or invalid refresh token.",
          },
        },
      };
    case "too_many_attempts":
      if (desc.includes("blocked")) {
        return {
          error: {
            status,
            data: { code: "blocked", message: "Your account has been locked" },
          },
        };
      }
      return {
        error: {
          status,
          data: {
            code: "unknown",
            message: "Unknown or invalid refresh token.",
          },
        },
      };
    default:
      return {
        error: {
          status,
          data: {
            code: "unknown",
            message: "Unknown or invalid refresh token.",
          },
        },
      };
  }
}

export interface WalletTokenBalance {
  tokenAddress: string;
  amount: string;
  decimals: number;
}

export interface TransactionParams {
  from: string;
  to: string;
  priorityFee: bigint;
  transfers: {
    amount: string;
    decimals: number;
    tokenAddress: string;
    symbol?: TokenInfo["symbol"];
  }[];
}

function buildBatchTransferTransaction({
  to,
  from,
  transfers,
  priorityFee,
}: TransactionParams) {
  if (transfers.length === 0) {
    throw new Error("No transfers provided");
  }

  if (transfers.length > MAX_TRANSFER_BATCH_SIZE) {
    throw new Error(
      `Maximum number of tokens to transfer is ${MAX_TRANSFER_BATCH_SIZE}, ${transfers.length} provided`,
    );
  }

  if (transfers.some((n) => BigInt(n.amount) <= 0)) {
    throw new Error("All transfers must have a valid amount");
  }

  const splTransfers = transfers.filter(
    (n) =>
      (n.tokenAddress === SOL_ADDRESS && n.symbol === "WSOL") ||
      n.tokenAddress !== SOL_ADDRESS,
  );
  const solTransfer = transfers.find(
    (n) => n.tokenAddress === SOL_ADDRESS && n.symbol === "SOL",
  );
  const cuLimit =
    splTransfers.length * CU_LIMIT_WITHDRAW_SPL_TOKEN +
    (solTransfer ? CU_LIMIT_WITHDRAW_NATIVE_SOL : 0);
  const cuPriceMicroLamports = calculateComputeUnitPriceMicroLamports(
    priorityFee.toString(),
    cuLimit,
  );

  const instructions: TransactionInstruction[] = [
    ComputeBudgetProgram.setComputeUnitLimit({ units: cuLimit }),
    ComputeBudgetProgram.setComputeUnitPrice({
      microLamports: cuPriceMicroLamports,
    }),
  ];
  const fromKey = new PublicKey(from);
  const toKey = new PublicKey(to);

  for (const transfer of splTransfers) {
    const tokenMint = new PublicKey(transfer.tokenAddress);
    const sourceTokenAccountPublicKey = getAssociatedTokenAddressSync(
      tokenMint,
      fromKey,
    );
    const destinationTokenAccountPublicKey = getAssociatedTokenAddressSync(
      tokenMint,
      toKey,
    );

    instructions.push(
      createAssociatedTokenAccountIdempotentInstruction(
        fromKey,
        destinationTokenAccountPublicKey,
        toKey,
        tokenMint,
      ),
      createTransferInstruction(
        sourceTokenAccountPublicKey,
        destinationTokenAccountPublicKey,
        fromKey,
        BigInt(transfer.amount),
      ),
      createCloseAccountInstruction(
        sourceTokenAccountPublicKey,
        fromKey,
        fromKey,
      ),
    );
  }

  if (solTransfer) {
    instructions.push(
      SystemProgram.transfer({
        fromPubkey: fromKey,
        toPubkey: toKey,
        lamports:
          BigInt(solTransfer.amount) +
          BigInt(splTransfers.length) * TOKEN_ACCOUNT_RENT -
          (SOL_TRANSACTION_FEE + priorityFee),
      }),
    );
  }

  const transaction = new Transaction().add(...instructions);
  transaction.feePayer = fromKey;

  return transaction;
}

function onAccountChange(
  address: string,
  connection: Connection,
  callback: (lamports: number) => void,
) {
  const subscriptionId = connection.onAccountChange(
    new PublicKey(address),
    (accountInfo, _context) => {
      callback(accountInfo.lamports);
    },
  );

  return () => {
    void connection.removeAccountChangeListener(subscriptionId);
  };
}

interface ParsedTokenAccount {
  type: "account";
  info: {
    isNative: boolean;
    mint: string;
    owner: string;
    state: "initialized";
    tokenAmount: {
      amount: string;
      decimals: number;
      uiAmount: number;
      uiAmountString: string;
    };
  };
}

export const web3authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    web3authSubmitOtp: builder.mutation<
      Web3authLoginResponse,
      { otp: string; email: string }
    >({
      query: ({ otp, email }) => ({
        url: `${import.meta.env.VITE_MARKETING_SITE_BASE_URL}/api/auth0/passwordless/login`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
        },
        body: JSON.stringify({ email, verificationCode: otp }),
      }),
      transformResponse: (response) => {
        return web3authLoginResponseSchema.parse(response);
      },
      transformErrorResponse: (response, meta) => {
        if (response.data) {
          const errorResponse = (response.data as GrantError).error
            ? (response.data as GrantError)
            : undefined;

          if (errorResponse) {
            return getAuth0Error(errorResponse, meta?.response?.status ?? 500);
          }
        }

        return { error: { status: 500, data: "Unknown error" } };
      },
    }),
    web3authRequestOtp: builder.mutation<
      boolean,
      { email: string; language: string }
    >({
      query: ({ email, language }) => ({
        url: `${import.meta.env.VITE_MARKETING_SITE_BASE_URL}/api/auth0/passwordless/start`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
        },
        body: JSON.stringify({
          email,
          send: "code",
          connection: "email",
          language,
        }),
      }),
    }),
    web3authRefreshToken: builder.mutation<
      OAuthResponse,
      { refreshToken: string }
    >({
      query: ({ refreshToken }) => ({
        url: `${import.meta.env.VITE_MARKETING_SITE_BASE_URL}/api/auth0/passwordless/refresh`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
        },
        body: JSON.stringify({ refreshToken }),
      }),
    }),
    web3authInputPassword: builder.mutation<boolean, { password: string }>({
      queryFn: async ({ password }) => {
        try {
          const instance = await Web3AuthClient.getInstanceAsync();

          if (!instance) {
            throw new Error("Web3AuthClient not initialized");
          }

          await instance.inputTKeyPassword(password);

          return { data: true };
        } catch (error) {
          return {
            error: {
              status: 500,
              data: (error as Error | undefined)?.message ?? "Unknown error",
            },
          };
        }
      },
    }),
    web3authTokens: builder.query<WalletTokenBalance[], void>({
      queryFn: async () => {
        try {
          const instance = await Web3AuthClient.getInstanceAsync();

          if (!instance) {
            throw new Error("Web3AuthClient not initialized");
          }

          const walletAddress = new PublicKey(instance.walletAddress);
          const { read } = await resolveRpcConnections();
          const result = await read.getParsedTokenAccountsByOwner(
            walletAddress,
            {
              programId: TOKEN_PROGRAM_ID,
            },
          );

          const solBalance = await read.getBalance(walletAddress);
          const tokens = result.value
            .map(({ account }) => {
              const data = account.data.parsed as
                | ParsedTokenAccount
                | undefined;

              if (data?.type === "account") {
                return {
                  tokenAddress: data.info.mint,
                  amount: data.info.tokenAmount.amount,
                  decimals: data.info.tokenAmount.decimals,
                };
              }

              console.warn("Unknown token account format", data);
              console.log(JSON.stringify(result.value, null, 2));
              return null;
            })
            .filter(Boolean)
            .map((n) => {
              if (!n) {
                throw new Error("not possible");
              }

              return n;
            });

          if (solBalance > 0) {
            tokens.push({
              amount: solBalance.toString(),
              tokenAddress: "",
              decimals: 9,
            });
          }

          return { data: tokens };
        } catch (error) {
          return {
            error: {
              status: 500,
              data: (error as Error | undefined)?.message ?? "Unknown error",
            },
          };
        }
      },
      providesTags: () => ["Web3authWalletBalance"],
      async onCacheEntryAdded(
        _arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        await cacheDataLoaded;
        const instance = await Web3AuthClient.getInstanceAsync();
        let unsub: (() => void) | undefined = undefined;

        if (instance) {
          const { read } = await resolveRpcConnections();

          unsub = onAccountChange(instance.walletAddress, read, (lamports) => {
            updateCachedData((data) => {
              return data.map((entry) => {
                if (entry.tokenAddress === "") {
                  return {
                    ...entry,
                    amount: lamports.toString(),
                  };
                }

                return entry;
              });
            });
          });
        }

        await cacheEntryRemoved;

        unsub?.();
      },
    }),
    web3authSendAndConfirmTransaction: builder.mutation<
      SendAndConfirmTransactionResult,
      TransactionParams
    >({
      queryFn: async (params) => {
        try {
          const instance = await Web3AuthClient.getInstanceAsync();

          if (!instance) {
            return {
              error: {
                status: 500,
                kind: "web3auth_not_initialized",
                data: new Error("Web3AuthClient not initialized"),
              },
            };
          }

          const transaction = buildBatchTransferTransaction(params);
          const { read, write } = await resolveRpcConnections();

          try {
            const blockhash = await read.getLatestBlockhash();
            transaction.recentBlockhash = blockhash.blockhash;
          } catch (error) {
            return {
              error: {
                status: 500,
                kind: "failed_to_fetch_latest_blockhash",
                data: error,
              },
            };
          }

          try {
            await instance.signTransaction(transaction);
          } catch (error) {
            return {
              error: { status: 500, kind: "failed_to_sign", data: error },
            };
          }

          try {
            const simulationResult =
              await read.simulateTransaction(transaction);
            if (simulationResult.value.err) {
              return {
                error: {
                  status: 409,
                  kind: "simulation_failed",
                  data: simulationResult,
                },
              };
            }
          } catch (error) {
            return {
              error: {
                status: 500,
                kind: "not_simulated",
                data: error,
              },
            };
          }

          const result = await sendAndConfirmTransaction({
            readConnection: read,
            writeConnections: write,
            transaction,
          });

          return { data: result };
        } catch (error) {
          return {
            error: {
              status: 500,
              kind: "unhandled_error",
              data: error,
            },
          };
        }
      },
      invalidatesTags: ["Web3authWalletBalance"],
      async onQueryStarted(arg, ctx) {
        ctx.dispatch(
          web3authApi.util.updateQueryData(
            "web3authTokens",
            undefined,
            (tokens) => {
              const transferedTokenAddresses = new Set(
                arg.transfers.map((transfer) => transfer.tokenAddress),
              );

              return tokens.filter(
                (transfer) =>
                  !transferedTokenAddresses.has(transfer.tokenAddress),
              );
            },
          ),
        );
        try {
          await ctx.queryFulfilled;
        } catch {
          web3authApi.util.invalidateTags(["Web3authWalletBalance"]);
        }
      },
    }),
  }),
});

export const {
  useWeb3authRefreshTokenMutation,
  useWeb3authRequestOtpMutation,
  useWeb3authSubmitOtpMutation,
  useWeb3authTokensQuery,
  useWeb3authSendAndConfirmTransactionMutation,
  useWeb3authInputPasswordMutation,
} = web3authApi;
