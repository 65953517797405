import bs58, { encode } from "bs58";
import {
  Quote,
  TokenInfo,
  useSaveCompletedUserOrderMutation,
  useSaveOrderQuoteDetailsMutation,
  useSendTransactionMutation,
  useSignTransactionMutation,
  useSwapMutation,
} from "@/api";
import { TRANSACTION_STATUS } from "@dflow-protocol/swap-api-utils";
import { VersionedTransaction } from "@solana/web3.js";
import { useDynamicWallet } from "./useDynamicWallet";
import {
  getPlatformFeeAccount,
  HAZE_REFERRAL_ACCOUNT,
  SOL_ADDRESS,
} from "@/utils";
import { useTypedSelector } from "@/store";

export const useSubmitQuote = () => {
  const [getSwap, swapMutation] = useSwapMutation();
  const [signTxMutate, signTxMutation] = useSignTransactionMutation();
  const [saveQuoteDetailsMutate] = useSaveOrderQuoteDetailsMutation();
  const [sendTransactionMutate, sendTransactionMutation] =
    useSendTransactionMutation();
  const [saveCompletedOrderMutate] = useSaveCompletedUserOrderMutation();

  const { useWrappedSol } = useTypedSelector((state) => state.profile);
  const walletAddress = useDynamicWallet()?.address;

  return {
    swapMutation,
    signTxMutation,
    sendTransactionMutation,
    submitQuote: async ({
      sendToken,
      receiveToken,
      quote,
      quoteStartedTimeStamp,
      quoteFulfilledTimeStamp,
      priorityFee,
      onBeforeSendTransaction,
    }: {
      sendToken: TokenInfo;
      receiveToken: TokenInfo;
      quote: Quote | undefined;
      quoteStartedTimeStamp: number | undefined;
      quoteFulfilledTimeStamp: number | undefined;
      priorityFee: number;
      onBeforeSendTransaction?: () => void;
    }) => {
      if (!quote || !walletAddress) return;

      const platformFeeAccount = getPlatformFeeAccount(quote);

      const swapParams = {
        userPublicKey: walletAddress,
        feeAccount: platformFeeAccount?.toBase58(),
        createFeeAccount: {
          referralAccount: HAZE_REFERRAL_ACCOUNT.toBase58(),
        },
        dynamicComputeUnitLimit: true,
        quoteResponse: quote,
        prioritizationFeeLamports: priorityFee,
        ...((sendToken.address === SOL_ADDRESS ||
          receiveToken.address === SOL_ADDRESS) && {
          wrapAndUnwrapSol: useWrappedSol ? false : true,
        }),
      };

      const timeOfSwapRequest = Date.now();
      const swapResponse = await getSwap(swapParams);
      if ("error" in swapResponse) return;
      const swap = swapResponse.data;
      const timeOfSwapReceived = Date.now();
      const swapTransaction = VersionedTransaction.deserialize(
        Buffer.from(swap.swapTransaction, "base64"),
      );
      const signResult = await signTxMutate(swapTransaction);
      if ("error" in signResult) return;
      const signedTransaction = signResult.data;
      if (signedTransaction.signatures.length === 0) {
        return;
      }
      const rawSignature = signedTransaction.signatures[0];
      const transactionSignature = encode(rawSignature);

      void saveQuoteDetailsMutate({
        userPublicKey: swapParams.userPublicKey,
        quoteRequestTimestamp: quoteStartedTimeStamp ?? 0,
        quoteRequestRawUrl: quote.requestUrl,
        quoteRequestParsedWellKnownParams: {
          inputMint: quote.requestParams.inputMint,
          outputMint: quote.requestParams.outputMint,
          quoteRequestAmount: quote.requestParams.amount,
          slippageBps: quote.requestParams.slippageBps ?? 0,
          quoteRequestPlatformFeeBps:
            quote.requestParams.platformFeeBps ?? null,
        },
        quoteResponseRawBody: JSON.stringify(swapParams.quoteResponse),
        quoteResponseParsedWellKnownFields: {
          quoteResponseInputAmount: swapParams.quoteResponse.inAmount,
          quoteResponseOutputAmount: swapParams.quoteResponse.outAmount,
          minOutAmount: swapParams.quoteResponse.minOutAmount,
          quoteResponsePlatformFeeBps:
            swapParams.quoteResponse.platformFee?.feeBps ?? null,
          quoteResponsePlatformFeeAmount:
            swapParams.quoteResponse.platformFee?.amount ?? null,
        },
        quoteResponseTimestamp: quoteFulfilledTimeStamp ?? 0,
        swapRequestTimestamp: timeOfSwapRequest,
        swapRequestRawBody: JSON.stringify(swapParams),
        swapResponseRawBody: JSON.stringify(swap),
        swapResponseTimestamp: timeOfSwapReceived,
        transactionSignature,
        sendTransactionTimestamp: Date.now(),
      });

      if (onBeforeSendTransaction) {
        onBeforeSendTransaction();
      }

      const sendResult = await sendTransactionMutate({
        signature: bs58.encode(rawSignature),
        transaction: signedTransaction,
        lastValidBlockHeight: swap.lastValidBlockHeight,
        quote: swapParams.quoteResponse,
        swapPair: {
          base: sendToken,
          quote: receiveToken,
        },
        priorityFeeUsed: priorityFee,
      });
      if ("error" in sendResult) return;
      if (sendResult.data.status === TRANSACTION_STATUS.EXPIRED) return;

      await saveCompletedOrderMutate({
        signature: sendResult.data.signature,
      });
    },
  };
};
